<template>
    <div class="m-2">
        <snapshot-select @update="(m) => maps = m" />
        <b-tabs class="mt-2">
            <b-tab title="Tags">
                <trend-tags v-if="maps" :maps="maps" />
            </b-tab>
            <b-tab title="Names">
                <trend-names v-if="maps" :maps="maps" />
            </b-tab>
            <b-tab title="Export">
                <export-csv v-if="maps" :maps="maps" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
export default {
    name: 'PopularTags',
    components: {
        SnapshotSelect: () => import('./components/SnapshotSelect.vue'),
        TrendTags: () => import('./components/TrendTags.vue'),
        TrendNames: () => import('./components/TrendNames.vue'),
        ExportCsv: () => import('./components/ExportCSV.vue'),
    },
    data() {
        return {
            maps: null,
        };
    },
};
</script>

